import { getNumeralDeclension } from '@glow/shared/utils/format';

export function formatCompanyCount(count: number) {
  const declension = getNumeralDeclension(count, [
    'компания',
    'компании',
    'компаний',
  ]);
  return `${count} ${declension}`;
}
